import type { AppProps } from 'next/app'
import { pdfjs } from 'react-pdf'
import { CHANNEL_TALK_KEY } from 'src/constants'
import { ChannelTalkProvider } from 'src/context/ChannelTalk'

// https://github.com/wojtekmaj/react-pdf/issues/136#issuecomment-940584082
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ChannelTalkProvider pluginKey={CHANNEL_TALK_KEY}>
      {/* eslint-disable-next-line */}
      {/*  @ts-ignore */}
      <Component {...pageProps} />
    </ChannelTalkProvider>
  )
}
export default MyApp

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-rest-params */
import React, { createContext, useContext, useRef } from 'react'
import { useEffectOnce } from 'react-use'

const ChannelTalkContext = createContext(
  {} as {
    isBooted: boolean
    showChannelTalkButton: VoidFunction
    hideChannelTalkButton: VoidFunction
  }
)

declare global {
  interface Window {
    ChannelIO?: (...args: any[]) => void
    ChannelIOInitialized: boolean
  }
}

export const ChannelTalkProvider: React.FC<{ pluginKey: string }> = ({
  pluginKey,
  children
}) => {
  const isBootedRef = useRef(false)

  const showChannelTalkButton = () => {
    if (!isBootedRef.current || !window.ChannelIO) {
      setTimeout(() => showChannelTalkButton(), 1000)
    } else {
      window.ChannelIO('showChannelButton')
    }
  }
  const hideChannelTalkButton = () => {
    if (!isBootedRef.current || !window.ChannelIO) {
      setTimeout(() => hideChannelTalkButton(), 1000)
    } else {
      window.ChannelIO('hideChannelButton')
    }
  }

  // Chanell IOのスクリプトを挿入
  useEffectOnce(() => {
    const w = window
    if (w.ChannelIO) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      )
    }

    const ch = function () {
      ch.c(arguments)
    }
    // @ts-ignore
    ch.q = []
    // @ts-ignore
    ch.c = function (args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch

    function l() {
      if (w.ChannelIOInitialized) {
        return
      }
      w.ChannelIOInitialized = true

      const s = document.createElement('script')

      s.type = 'text/javascript'

      s.async = true

      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'

      s.charset = 'UTF-8'

      const x = document.getElementsByTagName('script')[0]

      x.parentNode?.insertBefore(s, x)
    }

    if (document.readyState === 'complete') {
      l()
      // @ts-ignore
    } else if (window.attachEvent) {
      // @ts-ignore
      window.attachEvent('onload', l)
    } else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }

    // Channel IOの起動
    window.ChannelIO?.('boot', {
      pluginKey,
      hideChannelButtonOnBoot: true,
      language: 'ja'
    })
    isBootedRef.current = true
  })
  return (
    <ChannelTalkContext.Provider
      value={{
        isBooted: isBootedRef.current,
        showChannelTalkButton,
        hideChannelTalkButton
      }}
    >
      {children}
    </ChannelTalkContext.Provider>
  )
}

export const useChannelTalk = () => {
  const { hideChannelTalkButton, showChannelTalkButton } =
    useContext(ChannelTalkContext)

  useEffectOnce(() => {
    showChannelTalkButton()
    return () => hideChannelTalkButton()
  })
}

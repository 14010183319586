import { GoogleTagManagerId } from './components/common/GoogleTagManager'

export const SERVICE_NAME = 'TAX HERO'

// PostgreSQLのnumberの上限値2147483647
export const NUMBER_FORM_MAX_VALUE = 2147483647

export const LOCAL_STORAGE_IS_LOGIN_KEY = 'is_user_logged_in'
export const LOCAL_STORAGE_IS_TAX_ACCOUNTANT_LOGIN_KEY =
  'is_tax_accountant_logged_in'

export const COOKIE_AGE = 60 * 60 * 24 * 30
export const COOKIE_STATE_KEY =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? 'state'
    : `${process.env.NEXT_PUBLIC_ENV || 'local'}_state`
export const COOKIE_LINE_TOKEN_CODE = 'line_token_code'
export const COOKIE_LINE_TOKEN_NONCE = 'line_token_nonce'

export const COOKIE_IS_ADMIN_LOGIN_KEY = 'iak'

export const CATEGORY_GROUP_NUMBER = {
  PRIVATE: -1,
  ASSETS: 0,
  DEBIT: 1,
  NET_ASSETS: 2,
  INCOME: 3,
  EXPENSE: 4
}

export const REPORT_CATEGORY_HEADINGS = {
  INCOME: ['負債', '純資産', '収益'],
  PAYMENT: ['費用', '資産']
}

export const REPORT_CATEGORY_GROUP = [
  {
    value: CATEGORY_GROUP_NUMBER.PRIVATE,
    group: 'プライベート'
  },
  {
    value: CATEGORY_GROUP_NUMBER.ASSETS,
    group: '資産'
  },
  {
    value: CATEGORY_GROUP_NUMBER.DEBIT,
    group: '負債'
  },
  {
    value: CATEGORY_GROUP_NUMBER.NET_ASSETS,
    group: '純資産'
  },
  {
    value: CATEGORY_GROUP_NUMBER.INCOME,
    group: '収益'
  },
  {
    value: CATEGORY_GROUP_NUMBER.EXPENSE,
    group: '費用'
  }
]

export const PRIVATE_CATEGORY = {
  label: '個人事業に関係ないお金',
  options: [
    {
      value: CATEGORY_GROUP_NUMBER.PRIVATE,
      label: 'プライベート'
    }
  ]
}

export const FILTER_ACCOUNT_ITEM = {
  PRIVATE_INCOME: 'プライベートの支出',
  PRIVATE_PAYMENT: 'プライベートの収入'
}

export const GTM_ID: GoogleTagManagerId = 'GTM-M42KQ3Q'

export const CHANNEL_TALK_KEY = '0b02c31a-31da-4554-900b-371e181b201a'

export const JICOO_COUNSELING_URL =
  'https://www.jicoo.com/t/taxhero/e/counseling2023'

export const IMAGE_BASE_PATH =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? 'https://img.taxhero.jp'
    : process.env.NEXT_PUBLIC_ENV === 'staging'
    ? 'https://img-stg.taxhero.jp'
    : 'https://img-dev.taxhero.jp'
/**
 * pathの先頭は `/`がつく前提
 * @param path
 * @returns 'https://img{-dev | -stg}.taxhero.jp' + path を返す
 */
export const generateImageURL = (path?: string) =>
  path ? `${IMAGE_BASE_PATH}${path}` : ''

export const DEV_TYPEFORM_ID = 'njyGNUEw'
export const PROD_2022_TYPEFORM_ID = 'KlQAmbhd'
export const PROD_2023_TYPEFORM_ID = 'iCL5wUwG'
export const PROD_2023_ULTRA_INITIAL_TYPEFORM_ID = 'QJYAzeEh'
